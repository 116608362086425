import {useContext} from 'react';

import SessionContext from '@src/contexts/session';

type Result = {
  user: User | undefined;
  isLoading: boolean;
};

export type User = {
  sub?: string | null;
  email?: string | null;
  name?: string | null;
  picture?: string | null;
  org_id?: string | null;
  org_name?: string | null;
  jobTitle?: string | null;
};

export default function useUser(): Result {
  const session = useContext(SessionContext);

  return {
    isLoading: session.isLoading,
    user:
      session.user && session.organization
        ? {
            sub: session.user.id,
            email: session.user.email,
            name: [session.user.firstName, session.user.lastName]
              .filter(Boolean)
              .join(' '),
            picture: session.user.profilePictureUrl,
            org_id: session.organization.id,
            org_name: session.organization.name,
          }
        : undefined,
  };
}
