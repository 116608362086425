import {createContext} from 'react';

import {AbilityBuilder, MongoAbility, createMongoAbility} from '@casl/ability';

type Abilities =
  | ['audit', 'all']
  | ['invite', 'all']
  | [
      'manage',
      (
        | 'insights'
        | 'assets'
        | 'materials'
        | 'projects'
        | 'snippets'
        | 'tags'
        | 'assetFields'
        | 'projectFields'
        | 'roles'
        | 'files'
        | 'teams'
        | 'templates'
        | 'users'
      )
    ]
  | ['export', 'all']
  | ['share', 'all']
  | ['useAi', 'all'];

export type AppAbilities = MongoAbility<Abilities>;

const defaultPermissions = new AbilityBuilder<AppAbilities>(
  createMongoAbility
).build();

const PermissionsContext = createContext<AppAbilities>(defaultPermissions);

export default PermissionsContext;
