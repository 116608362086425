'use client';

import {ReactNode, useMemo} from 'react';

import {Avatar, Tab, Tabs} from '@mui/material';
import Box from '@mui/material/Box';
import Link from 'next/link';

import {useParams, usePathname, useSearchParams} from 'next/navigation';

import AccountAvatarMenu from '@src/components/layouts/core/app/AccountAvatarMenu';
import SupportButton from '@src/components/SupportButton';
import TetraDots from '@src/components/TetraDots';

import UniversalSearch from '@src/components/universal-search';

import {useOrganizationData} from '@src/hooks/useOrganizationData';
import usePermission from '@src/hooks/usePermission';

import useProjectLayoutData from '@src/hooks/useProjectLayoutData';
import useSessionsProject from '@src/hooks/useSessionsProject';
import useUser from '@src/hooks/useUser';

type NavigationHeader =
  | 'home'
  | 'projects'
  | 'insights-hub'
  | 'templates'
  | 'sessions';

interface Props {
  children: ReactNode;
}

export default function Layout({children}: Props) {
  const pathName = usePathname();
  const params = useParams();
  const searchParams = useSearchParams();
  const isSearchPage = pathName === '/search/';

  const {user} = useUser();
  const canManageTemplates = usePermission('manage', 'templates');

  const {organizationData} = useOrganizationData();
  const logoUrl = organizationData?.branding?.logo_url;

  const projectUid = params.projectUid ? String(params.projectUid) : undefined;

  const {projectData} = useProjectLayoutData(projectUid);
  const {goToSessionProject} = useSessionsProject();

  const isTemplate = searchParams.get('isTemplate')
    ? Boolean(searchParams.get('isTemplate'))
    : projectData?.attributes?.isTemplate;

  const isSession = projectData?.attributes?.isSessionsProject;

  const activeTab: NavigationHeader = useMemo(() => {
    if (pathName == '/') {
      return 'home';
    }
    if (pathName.startsWith('/templates') || (projectUid && isTemplate)) {
      return 'templates';
    }
    if (projectUid && isSession) {
      return 'sessions';
    }
    if (pathName.startsWith('/insights-hub')) {
      return 'insights-hub';
    }
    if (pathName.startsWith('/project')) {
      return 'projects';
    }
  }, [pathName, isTemplate, isSession]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flex: 'none',
          borderBottom: '1px solid #eee',
          paddingX: '16px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            alignItems: 'center',
            flex: '1',
            minWidth: 0,
          }}
        >
          <Link href="/">
            {logoUrl ? (
              <Avatar
                src={logoUrl}
                sx={{width: '32px', height: '32px'}}
                variant="rounded"
              />
            ) : (
              <TetraDots />
            )}
          </Link>

          <Tabs value={activeTab ?? false}>
            <Tab component={Link} href="/" label="Home" value="home" />
            <Tab
              component={Link}
              href="/projects"
              label="Projects"
              value="projects"
            />
            <Tab label="Sessions" value="sessions" onClick={goToSessionProject} />
            <Tab
              component={Link}
              href="/insights-hub"
              label="Insights Hub"
              value="insights-hub"
            />
            {canManageTemplates && (
              <Tab
                component={Link}
                href="/templates"
                label="Templates"
                value="templates"
              />
            )}
          </Tabs>
        </Box>

        <Box
          alignItems="center"
          display="flex"
          flex="1"
          justifyContent="center"
          minWidth={0}
        >
          {!isSearchPage && <UniversalSearch />}
        </Box>

        <Box
          alignItems="center"
          display="flex"
          flex="1"
          gap={1}
          justifyContent="flex-end"
          minWidth={0}
        >
          <SupportButton />
          <AccountAvatarMenu user={user} />
        </Box>
      </Box>

      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flex: '1',
          minHeight: 0,
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
