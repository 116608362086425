import {useContext} from 'react';

import PermissionsContext, {AppAbilities} from '@src/contexts/permissions';

const usePermission: AppAbilities['can'] = (...args) => {
  const permissions = useContext(PermissionsContext);
  return permissions.can(...args);
};

export default usePermission;
