import {SVGProps} from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  spin?: boolean;
  duration?: string;
}

export default function TetraDots({duration = '2s', spin, ...svgProps}: Props) {
  return (
    <svg
      fill="none"
      height={28}
      viewBox="0 0 26 28"
      width={26}
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      {spin && (
        <animateTransform
          attributeName="transform"
          dur={duration}
          keyTimes={'0; 0.1; 0.9; 1'}
          repeatCount="indefinite"
          type="rotate"
          values={'0; 0; 360; 360'}
        />
      )}
      <circle cx={21.79} cy={14.05} fill="#04D98B" r={2.674}>
        {spin && (
          <animate
            attributeName="cx"
            dur={duration}
            keyTimes="0; 0.1; 0.25; 0.75; 0.9; 1"
            repeatCount="indefinite"
            values="21.79; 21.79; 23; 23; 21.79; 21.79"
          />
        )}
        {spin && (
          <animate
            attributeName="cy"
            dur={duration}
            keyTimes="0; 0.1; 0.25; 0.75; 0.9; 1"
            repeatCount="indefinite"
            values="14.055; 14.055; 14; 14; 14.055; 14.055"
          />
        )}
      </circle>
      <circle cx={15.813} cy={10.594} fill="#19A1F5" r={2.674}>
        {spin && (
          <animate
            attributeName="cx"
            dur={duration}
            keyTimes="0; 0.1; 0.25; 0.75; 0.9; 1"
            repeatCount="indefinite"
            values="15.813; 15.813; 21.09; 21.09; 15.813; 15.813"
          />
        )}
        {spin && (
          <animate
            attributeName="cy"
            dur={duration}
            keyTimes="0; 0.1; 0.25; 0.75; 0.9; 1"
            repeatCount="indefinite"
            values="10.594; 10.594; 19.877; 19.877; 10.594; 10.594"
          />
        )}
      </circle>
      <circle cx={15.813} cy={17.515} fill="#19A1F5" r={2.674}>
        {spin && (
          <animate
            attributeName="cx"
            dur={duration}
            keyTimes="0; 0.1; 0.25; 0.75; 0.9; 1"
            repeatCount="indefinite"
            values="15.813; 15.813; 16.09; 16.09; 15.813; 15.813"
          />
        )}
        {spin && (
          <animate
            attributeName="cy"
            dur={duration}
            keyTimes="0; 0.1; 0.25; 0.75; 0.9; 1"
            repeatCount="indefinite"
            values="17.515; 17.515; 23.510; 23.510; 17.515; 17.515"
          />
        )}
      </circle>
      <circle cx={10.151} cy={7.134} fill="#6B85E5" r={2.674}>
        {spin && (
          <animate
            attributeName="cx"
            dur={duration}
            keyTimes="0; 0.1; 0.25; 0.75; 0.9; 1"
            repeatCount="indefinite"
            values="10.151; 10.151; 9.909; 9.909; 10.151; 10.151"
          />
        )}
        {spin && (
          <animate
            attributeName="cy"
            dur={duration}
            keyTimes="0; 0.1; 0.25; 0.75; 0.9; 1"
            repeatCount="indefinite"
            values="7.134; 7.134; 23.510; 23.510; 7.134; 7.134"
          />
        )}
      </circle>
      <circle cx={10.151} cy={14.055} fill="#6B85E5" r={2.674}>
        {spin && (
          <animate
            attributeName="cx"
            dur={duration}
            keyTimes="0; 0.1; 0.25; 0.75; 0.9; 1"
            repeatCount="indefinite"
            values="10.151; 10.151; 4.909; 4.909; 10.151; 10.151"
          />
        )}
        {spin && (
          <animate
            attributeName="cy"
            dur={duration}
            keyTimes="0; 0.1; 0.25; 0.75; 0.9; 1"
            repeatCount="indefinite"
            values="14.055; 14.055; 19.877; 19.877; 14.055; 14.055"
          />
        )}
      </circle>
      <circle cx={10.151} cy={20.975} fill="#6B85E5" r={2.674}>
        {spin && (
          <animate
            attributeName="cx"
            dur={duration}
            keyTimes="0; 0.1; 0.25; 0.75; 0.9; 1"
            repeatCount="indefinite"
            values="10.151; 10.151; 3; 3; 10.151; 10.151"
          />
        )}
        {spin && (
          <animate
            attributeName="cy"
            dur={duration}
            keyTimes="0; 0.1; 0.25; 0.75; 0.9; 1"
            repeatCount="indefinite"
            values="20.975; 20.975; 14; 14; 20.975; 20.975"
          />
        )}
      </circle>
      <circle cx={4.174} cy={3.674} fill="#2850E2" r={2.674}>
        {spin && (
          <animate
            attributeName="cx"
            dur={duration}
            keyTimes="0; 0.1; 0.25; 0.75; 0.9; 1"
            repeatCount="indefinite"
            values="4.174; 4.174; 4.909; 4.909; 4.174; 4.174"
          />
        )}
        {spin && (
          <animate
            attributeName="cy"
            dur={duration}
            keyTimes="0; 0.1; 0.25; 0.75; 0.9; 1"
            repeatCount="indefinite"
            values="3.674; 3.674; 8.122; 8.122; 3.674; 3.674"
          />
        )}
      </circle>
      <circle cx={4.174} cy={10.594} fill="#2850E2" r={2.674}>
        {spin && (
          <animate
            attributeName="cx"
            dur={duration}
            keyTimes="0; 0.1; 0.25; 0.75; 0.9; 1"
            repeatCount="indefinite"
            values="4.174; 4.174; 9.909; 9.909; 4.174; 4.174"
          />
        )}
        {spin && (
          <animate
            attributeName="cy"
            dur={duration}
            keyTimes="0; 0.1; 0.25; 0.75; 0.9; 1"
            repeatCount="indefinite"
            values="10.594; 10.594; 4.489; 4.489; 10.594; 10.594"
          />
        )}
      </circle>
      <circle cx={4.174} cy={17.515} fill="#2850E2" r={2.674}>
        {spin && (
          <animate
            attributeName="cx"
            dur={duration}
            keyTimes="0; 0.1; 0.25; 0.75; 0.9; 1"
            repeatCount="indefinite"
            values="4.174; 4.174; 16.090; 16.090; 4.174; 4.174"
          />
        )}
        {spin && (
          <animate
            attributeName="cy"
            dur={duration}
            keyTimes="0; 0.1; 0.25; 0.75; 0.9; 1"
            repeatCount="indefinite"
            values="17.515; 17.515; 4.489; 4.489; 17.515; 17.515"
          />
        )}
      </circle>
      <circle cx={4.174} cy={24.435} fill="#2850E2" r={2.674}>
        {spin && (
          <animate
            attributeName="cx"
            dur={duration}
            keyTimes="0; 0.1; 0.25; 0.75; 0.9; 1"
            repeatCount="indefinite"
            values="4.174; 4.174; 21.090; 21.090; 4.174; 4.174"
          />
        )}
        {spin && (
          <animate
            attributeName="cy"
            dur={duration}
            keyTimes="0; 0.1; 0.25; 0.75; 0.9; 1"
            repeatCount="indefinite"
            values="24.435; 24.435; 8.122; 8.122; 24.435; 24.435"
          />
        )}
      </circle>
    </svg>
  );
}
