import {useRouter} from 'next/navigation';
import useSWR from 'swr';

import {useCMSClient} from '@src/hooks/useCMSClient';
import generateUid from '@src/library/generateUid';

import useUser from './useUser';

const useSessionsProject = () => {
  const CMSClient = useCMSClient();
  const {user} = useUser();
  const router = useRouter();

  const {data: sessionProjectData, isLoading} = useSWR(
    user.sub && ['getSessionsProject', user.sub],
    async ([, userId]) => {
      const response = await CMSClient.getSessionsProject({
        userId,
      });

      return response?.projects?.data?.[0] ?? null;
    }
  );

  const createSessionsProject = async () => {
    const {getSessionsTemplates} = await CMSClient.getSessionsTemplates();
    const template = getSessionsTemplates.data[0];

    if (template) {
      const {createProjectFromTetraTemplate} =
        await CMSClient.createProjectFromTetraTemplate({
          templateProjectUid: template?.attributes?.uid,
        });
      return createProjectFromTetraTemplate?.attributes.uid;
    } else {
      const projectUid = generateUid();
      await CMSClient.upsertProject({
        projectUid: projectUid,
        input: {
          name: 'Sessions',
          isSessionsProject: true,
        },
      });

      return projectUid;
    }
  };

  const goToSessionProject = async () => {
    if (isLoading) {
      return;
    }
    if (sessionProjectData) {
      await router.push(`/project/${sessionProjectData.attributes.uid}`);
    } else {
      const projectUid = await createSessionsProject();
      await router.push(`/project/${projectUid}`);
    }
  };

  return {
    sessionProjectData,
    goToSessionProject,
  };
};

export default useSessionsProject;
