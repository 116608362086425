import {useSearchParams} from 'next/navigation';
import useSWR from 'swr';
import {useDebouncedCallback} from 'use-debounce';

import {useCMSClient} from '@src/hooks/useCMSClient';

import useOrganizationEventListener from './useOrganizationEventListener';

const useProjectLayoutData = (projectUid: string) => {
  const CMSClient = useCMSClient();
  const searchParams = useSearchParams();
  const isNew = searchParams.get('isNew');

  const {
    data: projectData,
    isLoading,
    mutate,
  } = useSWR(['getProjectSidebar', projectUid], async ([, key]) => {
    const response = await CMSClient.getProjectSidebar({
      filters: {
        uid: {
          eq: key,
        },
      },
    });

    return response?.projects?.data?.[0] ?? null;
  });

  const refresh = useDebouncedCallback(mutate, 1000);
  useOrganizationEventListener(({event}) => {
    if (
      event.type === 'plan-blocks-changed' ||
      (event.type === 'project-changed' && event.data.projectUid == projectUid)
    ) {
      refresh();
    }
  });

  const notFound = projectUid && !isLoading && !isNew && !projectData;
  return {
    projectData,
    notFound,
  };
};

export default useProjectLayoutData;
