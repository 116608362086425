import {Button} from '@mui/material';
import {ChatTeardropText} from '@phosphor-icons/react';
import {useIntercom} from 'react-use-intercom';

export default function SupportButton() {
  const {show} = useIntercom();

  return (
    <>
      <Button
        startIcon={<ChatTeardropText />}
        variant="text"
        onClick={() => {
          return show();
        }}
      >
        Support
      </Button>
    </>
  );
}
