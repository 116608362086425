import {createContext} from 'react';

import {User} from '@workos-inc/node';

export type SessionContextProps = {
  isLoading: boolean;
  user?: User;
  organization?: {
    id: string;
    name: string;
  };
};

const SessionContext = createContext<SessionContextProps>({
  isLoading: false,
});

export default SessionContext;
