import Avatar from '@mui/material/Avatar';
import Dialog, {DialogProps} from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import {Organization as WorkosOrganization} from '@workos-inc/node';

import useSWR from 'swr/immutable';

import LoadingView from '../LoadingView';
import {useSubdomain} from '@src/hooks/useSubdomain';

type Props = DialogProps & {userId: string};

export default function UserOrganizationsDialog({userId, ...dialogProps}: Props) {
  const subdomain = useSubdomain();
  const {data: organizations, isLoading} = useSWR(
    ['organizations', userId],
    async () => {
      const url = new URL('/api/workos/organizations', process.env.WEB_BASE_URL);

      if (subdomain) {
        url.host = `${subdomain}.${url.host}`;
      }

      const response = await fetch(url, {
        credentials: 'include',
      });

      return response.json() as unknown as Array<
        Pick<WorkosOrganization, 'id' | 'name'>
      >;
    }
  );

  return (
    <Dialog fullWidth maxWidth="sm" {...dialogProps}>
      <DialogContent sx={{display: 'flex', flexDirection: 'column'}}>
        {isLoading && <LoadingView />}

        <List>
          {organizations?.map((organization) => {
            return (
              <ListItem key={organization.id} disableGutters>
                <ListItemButton
                  sx={{borderRadius: '8px'}}
                  onClick={() => {
                    location.href = `/api/workos/switch-organization?organizationId=${organization.id}`;
                  }}
                >
                  <ListItemAvatar>
                    <Avatar src="gradient.jpeg" sx={{width: 24, height: 24}} />
                  </ListItemAvatar>
                  <ListItemText primary={organization.name} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
}
