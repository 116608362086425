import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import {useCMSClient} from './useCMSClient';

// Extracted from `auth0`
interface UpdateOrganization {
  name?: string | undefined;
  display_name?: string | undefined;
  branding?:
    | {
        logo_url?: string | undefined;
        colors?: {
          primary: string;
          page_background: string;
        };
      }
    | undefined;
  metadata?: any;
}

export function useOrganizationData() {
  const CMSClient = useCMSClient();

  const {data} = useSWR(['getCurrentOrganization'], async () => {
    const data = await CMSClient.getCurrentOrganization();
    const organization = data.currentOrganization;

    return {
      display_name: organization.name,
      branding: {
        logo_url: organization.logoUrl,
      },
    };
  });

  const updateOrganizationData = useSWRMutation(
    ['getCurrentOrganization'],
    async (_key, {arg}: {arg: UpdateOrganization}) => {
      await CMSClient.updateCurrentOrganization({
        input: {
          name: arg.display_name,
          logoUrl: arg.branding?.logo_url,
        },
      });
    }
  );

  return {
    organizationData: data,
    updateOrganizationData: updateOrganizationData.trigger,
  };
}
