import {Stack} from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Circular from '@components/core/display/progress/CircularProgress';

import {StyledBox} from './LoadingView.styles';
import {LoadingViewProps} from './LoadingView.types';

const LoadingView = (props: LoadingViewProps) => {
  const {loadingText} = props;

  return (
    <Container maxWidth="md">
      <StyledBox>
        <Stack alignItems="center" direction="column" spacing={2}>
          {loadingText && <Typography component="h6">{loadingText}</Typography>}
          <Circular aria-label="loading" color="primary" variant="indeterminate" />
        </Stack>
      </StyledBox>
    </Container>
  );
};

export default LoadingView;
