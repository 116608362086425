import Box from '@mui/material/Box';
import {styled} from '@mui/material/styles';

export const StyledBox = styled(Box)(() => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    textAlign: 'center',
  };
});
