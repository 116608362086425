import Box from '@mui/material/Box';
import {styled} from '@mui/material/styles';

export const StyledBox = styled(Box)(() => {
  return {
    '& .mainBtn': {
      position: 'relative',
      zIndex: 2,
      width: '32px',
      height: '32px',
      overflow: 'hidden',
      borderRadius: '8px',
    },
    '& .childAvatar': {
      width: '40px',
      height: '40px',
      borderRadius: '8px',
    },
  };
});
