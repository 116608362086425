import {useEffect, useMemo} from 'react';

import {useClient} from '@liveblocks/react';
import {useLatest} from 'react-use';

import {useSubdomain} from './useSubdomain';
import useUser from './useUser';

const useOrganizationEventListener = (callback) => {
  const client = useClient();

  const subdomain = useSubdomain();
  const {user} = useUser();

  const savedCallback = useLatest(callback);
  const room = useMemo(() => {
    if (!user) return;

    const roomId = `${subdomain}:${user.org_id}`;
    return client.getRoom(roomId);
  }, [subdomain, user]);

  useEffect(() => {
    if (!room) return;

    const listener = (eventData) => {
      savedCallback.current(eventData);
    };

    return room.events.customEvent.subscribe(listener);
  }, [room, savedCallback]);
};

export default useOrganizationEventListener;
